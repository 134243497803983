import { initMercadoPago, Payment } from '@mercadopago/sdk-react'
import Loading from '@common/utils/Loading'
import Error from '@common/error/Error'
import { useCreateMpPayment } from '@services/payment'

import { usePaymentHandler } from '@common/hooks/usePaymentHandler'

const mercadopago = import.meta.env.VITE_MERCADO_PAGO_PUBLIC_KEY
initMercadoPago(mercadopago, { locale: 'es-CL' })

function CreateMpPayment() {
  const { getHandleSuccessFunction, paymentAmount } = usePaymentHandler()

  const handlePayment = (params) => {
    mutate(params, paymentAmount)
  }

  // const handleSuccessFunction = getHandleSuccessFunction()
  const { isPending, isError, error, mutate } = useCreateMpPayment(
    getHandleSuccessFunction(),
  )

  if (isPending) return <Loading />

  return (
    <div className="mx-auto my-4 min-h-screen w-[95%] md:w-1/2">
      <Payment
        initialization={{
          amount: paymentAmount,
        }}
        customization={{
          paymentMethods: {
            creditCard: 'all',
            debitCard: 'all',
          },
        }}
        onSubmit={(params) => handlePayment(params)}
      />
      {isError && <Error message={error.message} />}
    </div>
  )
}

export default CreateMpPayment
