import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App.jsx'
import './assets/css/index.css'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './store/index'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { GoogleOAuthProvider } from '@react-oauth/google'
import FallBack from '@common/error/FallBack.jsx'
import { SessionStorageProvider } from '@common/context/sessionStorageContext'
import { AuthProvider } from '@common/context/AuthContext'

if (
  import.meta.env.VITE_NODE_ENV == 'testing' ||
  import.meta.env.VITE_NODE_ENV == 'production'
) {
  Sentry.init({
    dsn: 'https://d04cef3439a6520cf3d36496126f8241@o4506395521515520.ingest.sentry.io/4506395525316608',
    environment: import.meta.env.VITE_NODE_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/petsokey-testing\.onrender\.com/,
        ],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
        ),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const queryClient = new QueryClient()

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Sentry.ErrorBoundary fallback={FallBack}>
    <React.StrictMode>
      <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <SessionStorageProvider>
                <AuthProvider>
                  <App />
                </AuthProvider>
              </SessionStorageProvider>
            </PersistGate>
          </Provider>
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </React.StrictMode>
  </Sentry.ErrorBoundary>,
)
