import PropTypes from 'prop-types'
import { useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { FaPaw, FaLocationDot, FaSun } from 'react-icons/fa6'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { putData } from '@services/requests'
import { classNames } from '@utils/formatFunctions'
import smBlue from '@assets/images/home/sitter_card/smBlue.png'
import mdBlue from '@assets/images/home/sitter_card/mdBlue.png'
import lgBlue from '@assets/images/home/sitter_card/lgBlue.png'
import xlBlue from '@assets/images/home/sitter_card/xlBlue.png'
import ErrorNoCard from '@common/error/ErrorNoCard'
import Button from '@common/buttons/Button'
import 'dayjs/locale/es'

function RequestCard({ record, user, address, pets }) {
  dayjs.locale('es')
  const queryClient = useQueryClient()
  const sitter = useSelector((state) => state.user)
  const cardRef = useRef(null)
  const contentRef = useRef(null)
  const [height, setHeight] = useState('auto')
  const [isExpanded, setIsExpanded] = useState(false)

  const {
    isPending: updateIsPending,
    isError: updateIsError,
    error: updateError,
    mutate: updateServiceRequest,
  } = useMutation({
    mutationFn: async (status) => {
      const data = await putData(
        `service-record/${status}/${record.id}/${sitter.id}`,
      )
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['requestedServices'] })
    },
  })

  const handleClickOutside = (event) => {
    if (cardRef.current && !cardRef.current.contains(event.target)) {
      setIsExpanded(false)
    }
  }

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (contentRef.current) {
      setHeight(isExpanded ? `${contentRef.current.scrollHeight}px` : '0px')
    }
  }, [isExpanded])

  return (
    <div
      ref={cardRef}
      onClick={toggleExpand}
      className={classNames(
        'cursor-pointer space-y-2 rounded-lg p-2 transition-all duration-300 ease-in-out',
        'border border-grey-soft hover:bg-grey-soft',
        isExpanded &&
          record.type == 'dogWalking' &&
          'border-yellow-sec bg-yellow-100 hover:bg-yellow-100',
        isExpanded &&
          record.type == 'visit' &&
          'border-blue-ter bg-blue-100 hover:bg-blue-100',
        isExpanded &&
          record.type == 'dayCare' &&
          'border-purple-base bg-purple-100 hover:bg-purple-100',
      )}
    >
      <p>
        Solicitud de{' '}
        <span className="font-mont-semi">
          {record.type === 'dogWalking'
            ? 'Paseo'
            : record.type === 'visit'
              ? 'Visita'
              : record.type === 'dayCare'
                ? 'Alojamiento'
                : ''}
        </span>{' '}
        por {user.name} {user.lastName}
      </p>
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-2">
          <p
            className={classNames(
              'rounded-full px-2 py-1 text-sm text-slate-900',
              record.type === 'dogWalking' && isExpanded && 'bg-yellow-sec',
              record.type === 'dogWalking' &&
                'border border-yellow-sec bg-yellow-100 text-sm text-slate-900',
              record.type === 'visit' && isExpanded && 'bg-blue-ter',
              record.type === 'visit' &&
                'border border-blue-ter bg-blue-100 text-sm text-slate-900',
              record.type === 'dayCare' && isExpanded && 'bg-purple-base',
              record.type === 'dayCare' &&
                'border border-purple-base bg-purple-100 text-sm text-slate-900',
            )}
          >
            {dayjs(record.date).format('DD [de] MMMM')}
          </p>
          <p
            className={classNames(
              'rounded-full px-2 py-1 text-sm text-slate-900',
              record.type === 'dogWalking' && isExpanded && 'bg-yellow-sec',
              record.type === 'dogWalking' &&
                'border border-yellow-sec bg-yellow-100 text-sm text-slate-900',
              record.type === 'visit' && isExpanded && 'bg-blue-ter',
              record.type === 'visit' &&
                'border border-blue-ter bg-blue-100 text-sm text-slate-900',
              record.type === 'dayCare' && isExpanded && 'bg-purple-base',
              record.type === 'dayCare' &&
                'border border-purple-base bg-purple-100 text-sm text-slate-900',
            )}
          >
            {dayjs(record.date).format('HH:mm')} -{' '}
            {dayjs(record.date).add(1, 'hour').format('HH:mm')}
          </p>
        </div>
        <p
          className={classNames(
            'mr-4 text-3xl',
            record.type === 'dogWalking' && 'text-yellow-sec',
            record.type === 'visit' && 'text-blue-ter',
            record.type === 'dayCare' && 'text-purple-base',
          )}
        >
          {record.type === 'dogWalking' && <FaPaw />}
          {record.type === 'visit' && <FaLocationDot />}
          {record.type === 'dayCare' && <FaSun />}
        </p>
      </div>
      <p className="font-mont-semi">
        {address?.commune}, {address?.street}, {address?.department}
      </p>
      <div
        ref={contentRef}
        className="overflow-hidden transition-all duration-300 ease-in-out"
        style={{ height }}
      >
        {isExpanded && (
          <div className="flex flex-col gap-4 sm:flex-row sm:justify-between">
            <div className="space-y-4">
              <div className="flex flex-col gap-2">
                <p className="font-mont-semi">Mascotas</p>
                <div className="flex flex-wrap gap-4">
                  {pets.map((pet) => (
                    <div
                      key={pet.id}
                      className="flex flex-row items-center gap-2"
                    >
                      {pet.photoUrl ? (
                        <img
                          key={pet.id}
                          className="h-16 w-16 rounded-full object-cover sm:h-24 sm:w-24"
                          src={pet.photoUrl}
                          alt={pet.name}
                        />
                      ) : (
                        <div className="flex h-16 w-16 items-center justify-center rounded-full bg-grey-soft object-cover text-3xl text-grey-base">
                          <FaPaw />
                        </div>
                      )}
                      <div className="flex flex-col">
                        <p className="capitalize">{pet?.name}</p>
                        <p className="text-xs">{pet?.breed}</p>
                        <img
                          className="mt-1 h-6 w-6"
                          src={
                            pet.weight <= 6
                              ? smBlue
                              : pet.weight <= 18
                                ? mdBlue
                                : pet.weight <= 45
                                  ? lgBlue
                                  : xlBlue
                          }
                          alt={
                            pet.weight <= 6
                              ? 'small'
                              : pet.weight <= 18
                                ? 'medium'
                                : pet.weight <= 45
                                  ? 'large'
                                  : 'extra large'
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {record.message && (
                <p className="text-sm">
                  <span className="font-mont-semi">Detalles: </span>
                  {record.message}
                </p>
              )}
            </div>

            <div className="flex flex-row justify-end gap-2 text-sm sm:flex-col">
              <div className="w-1/2 sm:w-full">
                <Button
                  text="Aceptar"
                  bgColor="blue-soft"
                  textColor="white"
                  hoverColor="blue-600"
                  circleColor="white"
                  fillColor="blue-soft"
                  onClick={() => updateServiceRequest('accept')}
                  loading={updateIsPending}
                />
              </div>
              <div className="w-1/2 sm:w-full">
                <Button
                  text="Rechazar"
                  bgColor="grey-soft"
                  textColor="slate-900"
                  hoverColor="bg-grey-sec"
                  circleColor="slate-900"
                  fillColor="grey-soft"
                  onClick={() => updateServiceRequest('deny')}
                  loading={updateIsPending}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {updateIsError && <ErrorNoCard message={updateError.message} />}
    </div>
  )
}

RequestCard.propTypes = {
  record: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  address: PropTypes.object.isRequired,
  pets: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default RequestCard
