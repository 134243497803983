import axios from 'axios'
import * as Sentry from '@sentry/react'

const apiUrl = import.meta.env.VITE_API_URL || 'http://localhost:3000/api'

class RequestError extends Error {
  constructor(error) {
    const message =
      error?.response?.data?.message ||
      error?.message ||
      'Ups! Algo salió mal, por favor intenta nuevamente.'
    const data = error?.response?.data || {}
    const status = error?.response?.status || 500
    super(message)
    this.name = 'RequestError'
    this.data = data
    this.status = status
  }
}

const getData = async (route) => {
  try {
    const response = await axios.get(`${apiUrl}/${route}`)
    return response.data
  } catch (error) {
    Sentry.captureException(error)
    throw new RequestError(error)
  }
}

const deleteData = async (route) => {
  try {
    const response = await axios.delete(`${apiUrl}/${route}`)
    return response.data
  } catch (error) {
    Sentry.captureException(error)
    throw new RequestError(error)
  }
}

const putData = async (route, body = {}, headers = {}) => {
  try {
    const response = await axios.put(`${apiUrl}/${route}`, body, {
      headers: {
        ...headers,
      },
    })
    return response.data
  } catch (error) {
    Sentry.captureException(error)
    throw new RequestError(error)
  }
}

const postData = async (route, body = {}, headers = {}) => {
  try {
    const response = await axios.post(`${apiUrl}/${route}`, body, {
      headers: {
        ...headers,
      },
    })
    return response.data
  } catch (error) {
    Sentry.captureException(error)
    throw new RequestError(error)
  }
}

export { postData, getData, putData, deleteData }
