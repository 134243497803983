/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getData } from '@services/requests'
import ChatHeader from './ChatHeader'
import ChatInput from './ChatInput'
import ChatMessages from './ChatMessages'
import Loading from '@common/utils/Loading'

function Chat({ record, headerUser }) {
  const { isPending, data, refetch } = useQuery({
    queryKey: ['chat', record.id],
    queryFn: () => getData(`chat/${record.id}`),
    enabled: !!record,
  })

  useEffect(() => {
    if (record) {
      refetch()
    }
  }, [record, refetch])

  if (isPending) {
    return <Loading />
  }

  return (
    <div className="w-full overflow-hidden rounded-xl bg-white shadow-lg">
      <ChatHeader
        record={record}
        headerUser={headerUser}
        chatId={data?.chat?.id}
      />
      <ChatMessages record={record} messages={data?.messages || []} />
      <ChatInput record={record} chatId={data?.chat.id} />
    </div>
  )
}

Chat.propTypes = {
  record: PropTypes.object.isRequired,
  headerUser: PropTypes.object.isRequired,
}

export default Chat
